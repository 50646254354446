@import 'constants';
@import 'mixins';

.footer {
  width: 100%;
  display: flex;
  flex-flow: column;
  row-gap: 32px;
  padding: 64px $desktop-page-indent;

  @include media('<=laptop') {
    padding: 32px $desktop-page-indent;
  }

  &.dark {
    color: $rove-white;
    background-color: $rove-black;
  }

  &.light {
    color: $rove-black;
    background-color: $rove-off-white;
  }

  &.white {
    color: $rove-black;
    background-color: $rove-white;
  }

  &__upperBlock {
    display: grid;
    justify-content: space-between;
    grid-template-columns: minmax(374px, max-content) repeat(
        3,
        minmax(200px, max-content)
      );
    column-gap: 5vw;

    @include media('<=laptop') {
      display: flex;
      flex-flow: wrap;
      column-gap: 32px;
      row-gap: 24px;

      & > div {
        width: 100%;
      }
    }
  }

  &__newsletterWrapper {
    margin-bottom: 32px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;

    @include media('<=laptop') {
      width: 100%;
    }
  }

  &__lowerBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;

    &.dark {
      color: $rove-white;
    }

    &.light,
    &.white {
      color: $rove-black;
    }

    @include media('<=laptop') {
      flex-flow: column;
      align-items: flex-start;
    }
  }

  &__links {
    p {
      margin: 0;
    }
  }

  &__subtitle,
  &__link,
  &__links > a {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }

  &__link,
  &__links > a {
    margin-bottom: 6px;
    opacity: 0.7;
  }

  &__subtitle {
    margin-bottom: 4px;

    &.dark {
      color: $rove-white;
    }

    &.light,
    &.white {
      color: $rove-black;
    }
  }

  &__link {
    color: inherit;
  }

  &__inputField {
    width: 100%;
    max-width: 280px;
    margin-bottom: 11px !important;
    position: relative;

    &.dark {
      input {
        border-bottom: 1px solid $rove-white !important;
      }
    }

    &.light,
    &.white {
      input {
        border-bottom: 1px solid $rove-black !important;
      }
    }

    @include media('<=laptop') {
      max-width: 100%;
      margin-top: 7px !important;
    }

    input {
      border: none !important;
      border-bottom: 1px solid $rove-white !important;
      outline: none !important;
      background: transparent !important;
      padding: 8px 24px 8px 0 !important;
      font-size: 12px !important;
      font-weight: 400 !important;
      line-height: 18px !important;
    }
  }

  &__checkboxes {
    display: flex;
    column-gap: 8px;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
  }

  &__checkbox {
    label {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
    }
  }

  &__subscribeButton {
    position: absolute;
    right: 0;
    top: 8px;
    cursor: pointer;
    transition: transform 0.2s linear;

    &:hover {
      transform: scale(1.1);
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;

      &:hover {
        transform: none;
      }
    }
  }
}

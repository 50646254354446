@import 'constants';
@import 'mixins';

$transition: 0.5s linear all;

.adminHeader {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 $desktop-page-indent;
  width: 100%;
  z-index: $header-z-index;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  transition: $transition;
  color: $rove-black;

  @include media('<=laptop') {
    padding: 0 $mobile-page-indent;
  }

  &.isScrolled {
    background: rgba(255, 255, 255, 0.75);

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      backdrop-filter: blur(7.5px);
      -webkit-backdrop-filter: blur(7.5px);
    }
  }

  &__block {
    width: 118px;
    display: flex;
    align-items: center;
    column-gap: 15px;

    a {
      white-space: nowrap;
    }

    &.userBlock {
      direction: rtl;

      @include media('<=laptop') {
        display: none;
      }
    }
  }

  &__capsule {
    border-radius: 50px;
    padding: 0 16px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 32px;
    width: 225px;
    transition: $transition;
    background: $rove-off-white;

    @media (max-width: 1368px) {
      margin-left: -80px;
    }

    &.isScrolled {
      background: transparent;
      border: none;
    }

    @include media('<=laptop') {
      display: none;
    }
  }

  &__link {
    opacity: 0.6;
    font-weight: 500;
  }

  &__activeLink {
    opacity: 1;
    font-weight: 600;
  }

  &__dropdown {
    margin-top: 16px;
    background-color: rgba(29, 25, 25, 0.5);
    backdrop-filter: blur(5px);

    &.userDropdown {
      left: unset;
      right: 0;
    }
  }

  &__hiUser {
    direction: rtl;
    margin-left: auto;
    text-align: end;
    white-space: nowrap;
    font-weight: 500;
    font-size: 14px;
  }

  &__burgerIcon {
    display: none;

    @include media('<=laptop') {
      display: flex;
      flex-flow: column;
      row-gap: 5px;
      align-items: center;
      justify-content: center;
      background-color: rgba(29, 25, 25, 0.4);
      width: 30px;
      height: 30px;
      border-radius: 5px;

      & > div {
        background-color: $rove-off-white;
        height: 1px;
        width: 15.5px;
      }
    }
  }
}
